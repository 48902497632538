import React from 'react'
import { useLocation } from 'react-router'
import { Menu as UikitMenu } from '@plearn/uikit'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { useBurnedBalance, useTotalSupply } from 'hooks/useTokenBalance'
// import { useCheckWinner } from "hooks/useCheckWinner"
import { registerToken } from 'utils/wallet'
import { formatNumber, getBalanceNumber } from 'utils/formatBalance'
import { usePricePlearnBusd } from 'state/farms/hooks'
// import AnnouncementModal from 'components/Announcement/AnnouncementModal'
import tokens from 'config/constants/tokens'
import config from './config/config'
import UserMenu from './UserMenu'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'

const Menu = (props) => {
  const { isDark, toggleTheme } = useTheme()
  const plearnPriceUsd = usePricePlearnBusd()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useLocation()

  const totalSupply = useTotalSupply()
  const burnedBalance = getBalanceNumber(useBurnedBalance(tokens.plearn.address))
  const totalPlnSupply = totalSupply ? getBalanceNumber(totalSupply) - burnedBalance : 0
  const marketCap = plearnPriceUsd.times(totalPlnSupply)

  const activeMenuItem = getActiveMenuItem({ menuConfig: config(t), pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
  const tokenLogoUrl = `/images/tokens/${tokens.plearn.address}.png`
  const priceLinkHref =
    process.env.REACT_APP_BASE_CHAIN === 'BSC'
      ? 'https://swap.arken.finance/tokens/bsc/0xbe0d3526fc797583dada3f30bc390013062a048b'
      : process.env.REACT_APP_BASE_CHAIN === 'POLYGON' ? 'https://swap.arken.finance/tokens/polygon/0x9f545eaf5ffc3a69b9b5ffd53f7b39a549fb28cd' : 'https://swap.arken.finance/tokens/ethereum/0xBe0D3526fc797583Dada3F30BC390013062A048B' // `/swap?outputCurrency=${tokens.plearn.address}`

  const footerStats = {
    maxSupply: formatNumber(700 * 1000000, 0, 0),
    totalSupply: totalPlnSupply === 0 ? 'loading' : formatNumber(totalPlnSupply, 0, 0),
    // totalBurned: burnedBalance === 0 ? '0' : formatNumber(burnedBalance, 0, 0),
    // circulatingSupply: formatLocalisedCompactNumber(0), // TODO
    marketCap: marketCap.isNaN() ? 'loading' : `$${formatNumber(marketCap.toNumber(), 0, 0)}`,
  }

  return (
    <UikitMenu
      userMenu={<UserMenu />}
      globalMenu={<GlobalSettings />}
      isDark={isDark}
      showSwitchNetwork
      chainName="polygon"
      bscLinkHref={process.env.REACT_APP_BSC_BASE_URL}
      polygonLinkHref={process.env.REACT_APP_POLYGON_BASE_URL}
      ethereumLinkHref={process.env.REACT_APP_ETH_BASE_URL}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      showPrice={false}
      showCertik
      certikLink="/REP-Plearn-2021-11-30.pdf"
      cakePriceUsd={plearnPriceUsd.toNumber()}
      links={config(t)}
      subLinks={activeMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
      footerLinks={footerLinks(t)}
      tokenLogoUrl={tokenLogoUrl}
      priceLinkHref={priceLinkHref}
      activeItem={activeMenuItem?.href}
      activeSubItem={activeSubMenuItem?.href}
      buyCakeLabel={t('Buy PLN')}
      footerStats={footerStats}
      onAddMetamask={() => registerToken(tokens.plearn.address, tokens.plearn.symbol, tokens.plearn.decimals)}
      {...props}
    />
  )
}

export default Menu
