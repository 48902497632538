import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'PLN',
    lpAddresses: {
      80001: '0x8471Ee8b8a31eb231953Ab1c47350f43Dd874B7B',
      137: '0x9f545eaf5fFc3A69B9b5Ffd53f7B39A549fB28cd',
    },
    token: serializedTokens.earn,
    quoteToken: serializedTokens.wmatic,
  },
  // Locked Farm
  {
    pid: 1,
    lpSymbol: 'PLN-USDC LP',
    lpAddresses: {
      80001: '0xfaF0F5100881e7F3f398111919223F80620f70a2',
      137: '0x4154f789ac17a5d617634018B9d44A159EBAE347',
    },
    token: serializedTokens.plearn,
    quoteToken: serializedTokens.usdc,
    contractAddress: {
      80001: '0xD31466F96449AD8A76cD2cFcd206200663CdeD02',
      137: '0x33F7C9666c19f6d6A4DD45C96d7Abe500f43Cf70',
    },
    isLocked: true,
    lockPeriod: '3 days',
    poolWeightLockedFarm: '0',
    multiplierLockedFarm: '0',
    pendingWithdrawalContractAddress: {
      80001: '0xDF0333A2993354e85Aa9485549B76CE41955e434',
      137: '0x38b5c0fB6820638445bE1CD211fcbfF0099Be63E',
    },
  },
  {
    pid: 2,
    lpSymbol: 'PLN-MATIC LP',
    lpAddresses: {
      80001: '0xb0DD504E68567C896FF435c3806f18151dBDF853',
      137: '0x932C25E4de9AFb989e7eA3eDDd3dD8ED6E55643e',
    },
    token: serializedTokens.plearn,
    quoteToken: serializedTokens.wmatic,
    contractAddress: {
      80001: '0xD5E75e34eb9C01BfAAf789F5A58c0ff554249546',
      137: '0xEcd62d7125413f6B96382932C131a35cB5B55a1C',
    },
    isLocked: true,
    lockPeriod: '3 days',
    poolWeightLockedFarm: '0',
    multiplierLockedFarm: '0',
    pendingWithdrawalContractAddress: {
      80001: '0x9ef91342C2aA08Cd5cA5BdcF874D3c07E347150e',
      137: '0x97630FC7a52A924a949D1637249AB36644B5e377',
    },
  },
]

export default farms
