import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

export const NON_FOUNDING_POOL_ID = -1
export const FOUNDING_POOL_ID = []
export const LOCKED_POOL_ID = [1, 2]
export const SIX_POOL_ID = -1

const pools: SerializedPoolConfig[] = [
  //   {
  //     sousId: NON_FOUNDING_POOL_ID,
  //     stakingToken: serializedTokens.plearn,
  //     earningToken: serializedTokens.plearn,
  //     contractAddress: {
  //       80001: '0x7604914F8B69D05926D2b5D4363296b9227b8ae2',
  //       137: '',
  //     },
  //     poolCategory: PoolCategory.CORE,
  //     harvest: true,
  //     tokenPerBlock: '0',
  //     sortOrder: 2,
  //     isFinished: true,
  //   },
  {
    sousId: 1, // High-yield Pool 21 days
    stakingToken: serializedTokens.plearn,
    earningToken: serializedTokens.plearn,
    contractAddress: {
      80001: '0xC4c792FB3319A68E09Ca64fc0e66B32d1Ab8538A',
      137: '0x6c6AC68c19816ea16E00dB438b74Bb5cCf7b19ab',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0',
    pendingWithdrawalContractAddress: {
      80001: '0x68FAA04ec06febB8BCAbB2b039706266D7A5e525',
      137: '0x9fC8ADB092E7ff8d70688c774C86F5e3788532d1',
    },
    lockPeriod: '21 days',
    isFinished: true,
  },
  {
    sousId: 2, // High-yield Pool 7 days
    stakingToken: serializedTokens.plearn,
    earningToken: serializedTokens.plearn,
    contractAddress: {
      80001: '0x7FE94Ec726cd61519956e76b0AC922Da341f739a',
      137: '0xf8048d50b679E1ce08337346a0ee4A3A3cd4AF7C',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0',
    pendingWithdrawalContractAddress: {
      80001: '0xAa366c12F056A4B71E8e0490C58801a7cA213d23',
      137: '0xc59ccD6898F443f45fC09DE53DF05d9b2A1C815C',
    },
    lockPeriod: '7 days',
    isFinished: true,
  },
]

export default pools
